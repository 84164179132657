/* PopUp.css */
.popup-contenedor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
}

.popup {
  background-color: white;
  max-width: 90%; 
  padding: 20px;
  border-radius: 20px; /* Borde redondeado */
  overflow: hidden; /* Oculta el desbordamiento */
  position: relative; 
}

.popup-recupero {
  background-color: white;
  width: fit-content;
  height: fit-content; 
  border-radius: 10px; /* Borde redondeado */
  overflow: hidden; /* Oculta el desbordamiento */
  position: relative; 
}

.popup-image {
  width: 90%; 
  height: auto; 
}

.cerrarPopUp-btn{
  display: block;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 20px;
  top: 20px;
  width: auto;
  height: auto;
}

.cerrarPopUp-btn img{
  width: 4vh;
}


.cerrarPopUp-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* ---------------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .cerrarPopUp-btn{
    display: block;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 15px;
    top: 10px;
    width: auto;
    height: auto;
  }
  
  .cerrarPopUp-btn img{
    width: 2.5vh;
  }
}
