@import '~typeface-ubuntu/index.css';

/* Desktop styles */
@media(min-width: 769px){
  .texto-opciones{
    font-size: 2vw;
    color: #422A70;
    margin-left: 10px;
    margin-bottom: 30px;
  }
}

/* -------------------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px) {
  .texto-opciones{
    font-size: 4vw;
    color: #422A70;
    margin-bottom: 30px;
    margin-top: 10px;
    font-family: 'Ubuntu', sans-serif;
  }
}
