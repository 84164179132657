@import '~typeface-ubuntu/index.css';
.contenedor-principal-MR{
  display: flex;
  flex-direction: column;
  font-family: 'Ubuntu', sans-serif;
}  

.contenedor-contenido-MR{
  display: flex;
  flex-direction: column;
  font-family: 'Ubuntu', sans-serif;
  height: fit-content;
}  

/* Desktop styles */
@media(min-width: 769px){
  .contenedor-MR {
    display: flex;
    flex-direction: row;
    min-height: 170vh;
    height: fit-content;
    align-items: center ;
    width: 98vw;
    margin-top: -2%;
    padding: 0;
    background-color: white;
    position: relative;
    left: 0;
  }

  .contenedor-imagen-MR {
    width: 40%; 
    height: 140vh;
    justify-content: center;
    background-size: cover;
    position: absolute;
    left: 30px;
    top: 25px;
    padding-left: 5%;
    padding-top: 5%;
  }

  .contenedor-texto-MR {
    border-radius: 60px; /* Borde redondeado */
    width: 50%; 
    height: fit-content;
    min-height: 140vh;
    padding: 40px;
    width: 50vw;
    min-width: 500px;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombreado */;
    background-color: white;
    position: absolute;
    right: 40px;
    top: 25px;
  }

  .titulo-MR {
    font-size: 3.8vh;
    font-weight: 400;
    font-family: 'Ubuntu', 'sans-serif';
    color: #013545;
    text-align: left;
  }

  .texto-after-titulo-MR{
    font-size: 2.1vh;
    font-weight: 500;
  }

  .imagen-logo-MR{
    width: auto;
    margin: 15px;
}

  .texto-MR {
    font-size: 2.1vh;
    font-weight: 400;
  }

  .texto-MR a {
    font-size: 2.1vh;
    font-weight: 400;
    cursor: pointer;
    text-decoration:underline;
  }

  .texto-MR p,
  .texto-after-titulo-MR p{
    margin-bottom: -10px;
  }

  .subtitulo-MR {
    font-size: 2.8vh;
    font-weight: 700;
    margin-top: 35px;
    color: #013545;
  }

  .texto-n-MR {
    font-size: 2.4vh;
    font-weight: 600;
    margin-top: 25px;
  }
  
  .enlace-video-MR {
    font-size: 2.1vh;
    font-weight: 400;
    color:black;
  }

  .contenedor-formulario-MR {
    margin-top: 10px;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
    height: fit-content;
  }
}

/* ---------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .contenedor-MR {
    display: flex;
    flex-direction: column;
    min-height: 190vh;
    height: fit-content;
    align-items: center ;
    width: 98vw;
    margin-top: 5%;
    padding: 0;
    background-color: white;
    position: relative;
    left: 0;
  }

  .contenedor-imagen-MR {
    width: 100%; 
    height: 60vh;
    justify-content: center;
    padding-left: 10%;
    margin-top: 2%;
  }

  .contenedor-texto-MR {
    border-radius: 60px; /* Borde redondeado */
    width: 100%; 
    height: fit-content;
    padding: 40px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombreado */;
    background-color: white;
  }

  .titulo-MR {
    font-size: 3.2vh;
    font-weight: 500;
    font-family: 'Ubuntu', 'sans-serif';
    color: #013545;
    text-align: center;
  }

  .texto-after-titulo-MR{
    font-size: 2.1vh;
    font-weight: 500;
  }

  .imagen-logo-MR{
    width: auto;
    margin: 15px;
}

  .texto-MR {
    font-size: 2.1vh;
    font-weight: 400;
  }

  .texto-MR p,
  .texto-after-titulo-MR p{
    margin-bottom: -10px;
  }

  .subtitulo-MR {
    font-size: 2.8vh;
    font-weight: 700;
    margin-top: 35px;
    color: #013545;
  }

  .texto-n-MR {
    font-size: 2.4vh;
    font-weight: 600;
    margin-top: 25px;
  }
  
  .enlace-video-MR {
    font-size: 2.1vh;
    font-weight: 400;
    color:black;
  }

  .contenedor-formulario-MR {
  margin-top: 5%;
  margin-bottom: 5%;
  }
}