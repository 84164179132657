/* PopUp.css */
@import '~typeface-ubuntu/index.css';

.popup-login-contenedor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
}
  
.popup-login {
  background-color: white;
  max-width: 90%; 
  height: 90%;
  border-radius: 20px;
  overflow: hidden; /* Oculta el desbordamiento */
  position: relative; 
}

.iframe-container {
  border-radius: 10%;
  overflow: hidden;
}
  
.cerrarPopUp-btn{
  display: block;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 20px;
  top: 20px;
  width: auto;
  height: auto;
  cursor: pointer;
}
  
.cerrarPopUp-btn img{
  width: 4vh;
}
  
  
.cerrarPopUp-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.popup-cotizacion {
  background-color: rgba(1, 53, 69, 0.9);
  width: 25%; 
  height: 30%;
  border-radius: 20px; 
  overflow: hidden; /* Oculta el desbordamiento */
  position: relative; 
  align-content: center;
  align-items: center;
}

.opciones-popup-cotizacion {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  color: white;
}

.opciones-popup-cotizacion a,
.opciones-popup-cotizacion p{
  margin-bottom: 0;
  font-size: 3.9vh;
  cursor: pointer;
}
  
.opciones-popup-cotizacion a:hover{
  text-decoration: underline white;
}

/* ---------------------------------------- */ 

/* Mobile styles */
@media(max-width: 768px){
  .cerrarPopUp-btn{
    display: block;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 15px;
    top: 10px;
    width: auto;
    height: auto;
  }

  .popup-login {
    background-color: white;
    max-width: 90%; 
    height: 70%;
    border-radius: 20px; 
    overflow: hidden; /* Oculta el desbordamiento */
    position: relative; 
  }
    
  .cerrarPopUp-btn img{
    width: 2.5vh;
  }

  .iframe-mi-cuenta{
    height: 70vh;
  }

  .popup-cotizacion {
    background-color: rgba(1, 53, 69, 0.9);
    width: 50%; 
    height: 30%;
    border-radius: 20px; 
    overflow: hidden; /* Oculta el desbordamiento */
    position: relative; 
    align-content: center;
    align-items: center;
  }

  .opciones-popup-cotizacion a,
  .opciones-popup-cotizacion p{
    margin-bottom: 0;
    font-size: 2.9vh;
    cursor: pointer;
  }
}
  