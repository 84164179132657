@import '~typeface-ubuntu/index.css';

/* Desktop styles */
@media(min-width: 769px){
  .tipo-plan-btn {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 10vw;
    height: 4vw;
    min-width: 100px;
    min-height: 40px;
    padding: 10px 20px;
    font-size: 1vw;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 550;
    border-radius: 10px;
    border: none;
    color: white;
    background: linear-gradient(to right, #323366, #069999); 
    cursor: pointer;
    transition: background 0.3s;
    letter-spacing: 1px;
  }

  .tipo-plan-btn:hover{
    background: linear-gradient(to right, #E51C30, #422A70);
  }

  .tipo-plan-btn-asesoria-plus{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 7vw;
    height: 2vw;
    padding: 10px 20px;
    margin-right: 40px;
    font-size: 0.7vw;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    border: none;
    color: white;
    background: linear-gradient(to right, #323366, #069999); 
    cursor: pointer;
    transition: background 0.3s;
    letter-spacing: 1px;
  }
}

/* -------------------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .tipo-plan-btn {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 20vw;
    height: 5vw;
    min-width: 150px;
    min-height: 40px;
    padding: 10px 20px;
    font-size: 3vw;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    border: none;
    color: white;
    background: linear-gradient(to right, #323366, #069999); 
    cursor: pointer;
    transition: background 0.3s;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }

  .tipo-plan-btn-asesoria-plus{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25vw;
    height: 5vw;
    padding: 10px 20px;
    margin-bottom: 10px;
    font-size: 2.2vw;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    border: none;
    color: white;
    background: linear-gradient(to right, #323366, #069999); 
    cursor: pointer;
    transition: background 0.3s;
    letter-spacing: 1px;
  }
}
  