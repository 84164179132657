@import '~typeface-ubuntu/index.css';

/* Desktop styles */
@media(min-width: 769px){
  .piedepagina {
    display: flex;
    justify-content: space-between;
    padding: 20px; 
    background-color: #422A70;
    width: 100%;
    height: 42vh;
    box-sizing: border-box;     /* Asegura que el padding no afecte el ancho total */
    margin-left: auto; /* Centra el pie de página en el espacio disponible */
    margin-right: auto;
    position: relative;
    z-index: 3;
  }
    
  .piedepagina::after {
    content: '';
    background-image: url('../media/ondas.pie.svg'); 
    background-position: bottom right; /* Ajusta la posición de la imagen */
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    right: -0.25%;
    width: 60%;
    height: 45vh;  
    z-index: -1;
  }
    
  .parrafopdp p {
    margin: 0.5vw 0;
  }
      
  .textoIzquierda,
  .textoDerecha{
    font-family: 'Ubuntu', sans-serif;
    font-size: 2.45vh;
    font-weight: 400;
    color: white;
    width: 50%;
    margin-top: 2.5vw;
    padding: 5px; 
    letter-spacing: 0.5px;       
  }
    
  .textoIzquierda{
    margin-left: 8vw;
  }
    
  .textoDerecha{
    margin-left: 2vw
  }
    
  .logoPieDePagina{
    width:20vw;
    height: auto;
    margin-top: 30px;
  }      
}

/* -------------------------------------------------------- */

/* Mobile styles */
@media (max-width: 768px) {
  .piedepagina {
    flex-direction: column;
    align-items: center;
    height: 14%; 
    padding: 20px;
    background-color: #422A70;
    background-image: url('../media/ondas.pie\ 1.svg'); 
    background-position: bottom right; 
    background-repeat: no-repeat;
    background-size: 66vw; 
  }
  
  .textoIzquierda,
  .textoDerecha {
    width: 100%;
    text-align: left; 
    margin: 0;
    font-family: 'Ubuntu', sans-serif;
    font-size: 12px;
    font-weight: 200;
    color: white;
  }
  
  .logoPieDePagina {
    width: 160px; /* Ajusta el tamaño del logo para dispositivos móviles */
    margin-bottom: 2px;
    margin-top: 2px;
  }
  
  .parrafopdp p {
    margin: 5px 0; /* Ajusta el espaciado entre párrafos en dispositivos móviles */
  }
}