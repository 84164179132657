@import '~typeface-ubuntu/index.css';

/* Desktop styles */
@media(min-width: 769px){
  .Cotizador, .cotizador-ancho{
    font-family: 'Ubuntu', sans-serif;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    left: 0;
    width: 100%;
    object-fit: cover;
    z-index: 2;
    position: relative;
    min-height: 400px;
  }

  .vista-cotizacion{
    font-family: 'Ubuntu', sans-serif;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    left: 0;
    height: 90vh; 
    width: 100%;
    object-fit: cover;
    z-index: 2;
    position: relative;   
  }

  .vista-infoCotizacion{
    font-family: 'Ubuntu', sans-serif;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    left: 0;
    height: fit-content; 
    width: 100%;
    object-fit: cover;
    z-index: 2;
    position: relative;   
  }

  .centrar-cotizador{
    align-items: center;
  }

  .imagen-fondo {
    background-image: url('../media/onda1\ 1.svg');
    background-repeat: no-repeat;
    height: 100vh;
    min-height: 400px;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: absolute;
    bottom: 0;
  }

  .imagen-paso2-cotizacion {
    background-image: url('../media/onda1\ 1.svg');
    background-repeat: no-repeat;
    height: 100vh;
    min-height: 400px;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: absolute;
    bottom: 0;
  }

  .imagen-paso2-cotizacion-enInicio {
    background-color: white;
    height: 100vh;
    min-height: 400px;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: absolute;
    bottom: 0;
  }

  .imagen-fondo-vista-cotizacion {
    background-image: url('../media/onda3\ 1.svg');
    background-repeat: no-repeat;
    height: fit-content;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .imagen-fondo-vista-cotizacion-enInicio {
    background-color: white;
    height: fit-content;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .color-fondo-inicio {
    background-color: #013545;
    z-index: 2;
    background-position: center;
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .color-fondo-asesoria{
    background-color: #72B8BD;
    z-index: 2;
    background-position: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .contenedor-btn-back{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: fit-content;
    height: auto;    
    margin-top: -10vh;
    margin-left: -52%;
  }

  .btn-back{
    height: 50px;
    width: 200px;
    background: #72B8BD;
    font-size: 1vw;
    border-radius: 10px;
    cursor: pointer;
  }

  .btn-back:hover{
    background: linear-gradient(to right, #E51C30, #422A70);
  }

  .contenedor-imagen-puntos-cotizacion{
    display: flex;
    position: absolute;
    left: 0.5%;
    margin-top: 3.5%;
  }
  
  .icono-puntos-cotizacion {
    width: 16vw; 
    height: 6vw;
  }

  }

/* ---------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .Cotizador{
    font-family: 'Ubuntu', sans-serif;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    left: 0;
    width: 100%;
    object-fit: cover;
    z-index: 2;
    position: relative;
  }

  .color-fondo-inicio {
    background-color: #013545;
    z-index: 2;
    background-position: center;
    width: 100%;
  }

  .color-fondo-asesoria{
    background-color: #72B8BD;
    z-index: 2;
    background-position: center;
    width: 100%;
  }

  .cotizador-ancho{
    width: 100%;
  }
    
  .icono-puntos-cotizacion {
    width: 0vw; 
    height: 0vw;
  }
}