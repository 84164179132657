/* MiCuenta.css */

.contenedor-principal-registro {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  font-family: 'Ubuntu', sans-serif;  
  }  

/* Desktop styles */
@media(min-width: 769px){
  .contenedor-texto-registro {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin: 0;
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    height: 120vh;
    width: 100%;
  }
  
  .contenedor-imagen-registro {
    width: 42vw; 
    height: 110vh;
    justify-content: center;
    background-image: url('../media/FotoRegistro.png');
    background-size: cover;
    position: absolute;
    left: 0;
  }
  
  .contenedor-texto-registrate {
    width: 60%; 
    height: 120vh;
    display: flex;
    flex-direction: column;
    align-content: left;
    justify-content: left;
    text-align: left;
    position: absolute;
    right: 0;
  }

  .contenedor-texto-registrate p{
    font-family: 'Ubuntu', sans-serif;
    font-size: 12.5vh;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #013545;
    margin-top: 0;
    margin-left: 15vh;
    height: 2.5vh;
  }

  .subrayado-registrate{
    margin-top: 0;
    margin-left: 15vh;
    border-top: 0.7vw solid red;
    width: 55vh;
  }

  .contenedor-registrate{
    position: absolute;
    top: 20%;
    left: 15vh;
    height: 35vw;
    width: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .contenedor-backoffice-registro{
    position: absolute;
    top: 0;
    left: 0;
    width: fit-content;
  }

  .iframe-registro{
    border: none;
  }
} 

/* -------------------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .contenedor-texto-registro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end; 
    margin: 0;
    height: 140vh;
    width: 100%;
    position: relative;
    background-color: white; 
  }

  .contenedor-registrate{
    background-color: white;
  }

  .contenedor-imagen-registro {
    background-image: url('../media/FotoRegistro.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 55vh;
    margin-bottom: 0; 
  }

  .contenedor-texto-registrate {
    width: 100%; 
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: left;
    justify-content: left;
    text-align: left;
    position: absolute;
    top: 5%;
    right: 0;
  }

  .contenedor-texto-registrate p{
    font-family: 'Ubuntu', sans-serif;
    font-size: 14vw;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #013545;
    margin-top: 0;
    margin-left: 0;
  }

  .subrayado-registrate{
    margin-top: -14%;
    margin-bottom: 2%;
    margin-right: 15vh;
    border-top: 1.3vw solid red;
    width: 40vh;
  }

  .iframe-registro{
    width: 100vw;
  }

  .btn-cancelar-registro{
    width: 125px;
    height: 45px;
    padding: 10px 10px;
    text-align: center;
    font-size: 2vh;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    border: none;
    color: white;
    background: gray;
    cursor: pointer;
    margin: 0 auto; 
    margin-top: -5%;
    margin-left: 5%;
    z-index: 1;
  }

  .btn-cancelar-registro:hover{
    font-size: 2.8vh;
    width: 17vh;
    height: 6.5vh;
  }
}
  
  