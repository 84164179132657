@import '~typeface-ubuntu/index.css';

/* Desktop styles */
@media(min-width: 769px){
.box-formulario-MR {
    border-radius: 60px; /* Borde redondeado */ 
    height: fit-content;
    min-height: 30vh;
    padding: 40px;
    width: 70vw;
    min-width: 500px;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombreado */;
    background-color: white;
    font-family: 'Ubuntu', sans-serif;
  }

  .seccion-botones-formulario{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }

  .titulo-formulario-elegir{
    font-size: 3.8vh;
    font-weight: 700;
    font-family: 'Ubuntu', 'sans-serif';
    color: #013545;
    text-align: left;
    margin-bottom: 2%;
  }

  .btn-tipoRenta,
  .btn-tipoRenta-selected {
    width: fit-content;
    height: 5vh;
    padding: 10px 20px;
    font-size: 2vh;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    border: none;
    color: white;
    cursor: pointer;
    letter-spacing: 1px;
    margin-left: 2%; 
  }

  .btn-tipoRenta{
    background: linear-gradient(to left, #323366, #069999);
  }

  .btn-tipoRenta-selected,
  .btn-tipoRenta:hover{
    background: linear-gradient(to left, #E51C30, #422A70);
  }

  .subtitulo-formulario-elegir{
    font-size: 1.9vh;
    font-weight: 400;
    margin-bottom: 1%;
    color: #013545;
  }

  .info-plan-MR{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: justify;
  }

  .titulo-plan-MR{
    font-size: 2.5vh;
    font-weight: 700;
    font-family: 'Ubuntu', 'sans-serif';
    color: black;
    margin-bottom: 5%;
    text-align: center;
  }

  .descripcion-plan-MR{
    font-size: 1.8vh;
    font-weight: 400;
    margin-bottom: 1%;
    color: black;
  }


  .formulario-textos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

  .contenedor-formapago-enviar{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .elegir-formapago-formulario{
    display: flex;
    flex-direction: column;
    width: 60vh;
  }

  .opcion-formapago-formulario{
    display: flex;
    flex-direction: row;
  }

  .btn-formaPago {
    position: relative;
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-right: 10px;
  }
  
  .btn-formaPago::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 2px solid #333;
    border-radius: 50%;
  }
  
  .btn-formaPago-selected {
    position: relative;
    padding: 10px 20px;
    border: 1px solid #333;
    border-radius: 20px;
    background-color: #333;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  
  .btn-formaPago-selected::before {
    content: '•';
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #fff;
  }
  
  .contenedor-tarjeta-numero{
    display: flex;
    flex-direction: row;
  }

  .dropdown-tarjeta{
    width: 15vh;
    height: 5vh;
    margin-right: 10px;
  }

  label {
    margin-bottom: 10px; 
    font-size: 16px; 
    color: #333; 
    width: 100%;
  }

  input {
    width: 100%;
    padding: 8px 0; 
    margin-top: 10px;
    margin-bottom: 15px;
    border: none;
    border-bottom: 1px solid #013545; /* Línea inferior */
    background-color: transparent; 
    font-size: 16px;
  }

  button[type="submit"] {
    width: 20vh;
    height: 6vh;
    padding: 10px 20px;
    font-size: 2.5vh;
    font-weight: 600;
    border-radius: 10px;
    border: none;
    color: white;
    cursor: pointer;
    letter-spacing: 1px;
    margin-left: 2%; 
    background: linear-gradient(to left, #E51C30, #422A70);
  }

  button[type="submit"]:hover {
    background-color: #0056b3;
  }

/* Estilos para los inputs cuando están enfocados */
  input:focus {
    outline: none; 
    border-bottom-color: #007bff; /* Cambiar el color de la línea inferior */
  }

  .mensaje-enviado-formulario{
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: lightgray;
    padding: 10px 20px;
    font-size: 2.5vh;
    text-align: center;
    width: 96%;
  }
  
  .titulo-formulario-elegir a{
    cursor: pointer;
  }
}

  /* ---------------------------------------------- */

  /* Mobile styles */
  @media(max-width: 768px){
  
  .box-formulario-MR {
    border-radius: 60px; /* Borde redondeado */ 
    height: fit-content;
    min-height: 30vh;
    padding: 40px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombreado */;
    background-color: white;
    font-family: 'Ubuntu', sans-serif;
  }

  .seccion-botones-formulario{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .titulo-formulario-elegir{
    font-size: 3.8vh;
    font-weight: 700;
    font-family: 'Ubuntu', 'sans-serif';
    color: #013545;
    text-align: left;
    margin-bottom: 2%;
  }

  .btn-tipoRenta,
  .btn-tipoRenta-selected {
    width: fit-content;
    height: 5vh;
    /* min-width: 45vh; */
    padding: 10px 20px;
    font-size: 2vh;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    border: none;
    color: white;
    cursor: pointer;
    letter-spacing: 1px;
    margin-bottom: 2%; 
  }

  .btn-tipoRenta{
    background: linear-gradient(to left, #323366, #069999);
  }

  .btn-tipoRenta-selected,
  .btn-tipoRenta:hover{
    background: linear-gradient(to left, #E51C30, #422A70);
  }

  .subtitulo-formulario-elegir{
    font-size: 1.9vh;
    font-weight: 400;
    margin-bottom: 1%;
    color: #013545;
  }

  .info-plan-MR{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: justify;
  }

  .titulo-plan-MR{
    font-size: 2vh;
    font-weight: 700;
    font-family: 'Ubuntu', 'sans-serif';
    color: black;
    margin-bottom: 5%;
    text-align: center;
  }

  .descripcion-plan-MR{
    font-size: 1.3vh;
    font-weight: 400;
    margin-bottom: 1%;
    color: black;
  }


  .formulario-textos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

  .contenedor-formulario-MR{
    width:100%
  }

  .contenedor-formapago-enviar{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 2%;
  }

  .elegir-formapago-formulario{
    display: flex;
    flex-direction: column;
    width: 40vh;
  }

  .opcion-formapago-formulario{
    display: flex;
    flex-direction: row;
  }

  .btn-formaPago {
    position: relative;
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-right: 10px;
  }
  
  .btn-formaPago::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 2px solid #333;
    border-radius: 50%;
  }
  
  .btn-formaPago-selected {
    position: relative;
    padding: 10px 20px;
    border: 1px solid #333;
    border-radius: 20px;
    background-color: #333;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  
  .btn-formaPago-selected::before {
    content: '•';
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #fff;
  }
  
  .contenedor-tarjeta-numero{
    display: flex;
    flex-direction: row;
  }

  .dropdown-tarjeta{
    width: 10vh;
    height: 5vh;
    margin-right: 10px;
  }

  label {
    margin-bottom: 10px; 
    font-size: 16px; 
    color: #333; 
    width: 100%;
  }


  input {
    width: 100%;
    padding: 8px 0; 
    margin-top: 10px;
    margin-bottom: 15px;
    border: none;
    border-bottom: 1px solid #013545; /* Línea inferior */
    background-color: transparent; 
    font-size: 16px;
  }

  button[type="submit"] {
    width: 20vh;
    height: 6vh;
    padding: 10px 20px;
    font-size: 2.5vh;
    font-weight: 600;
    border-radius: 10px;
    border: none;
    color: white;
    cursor: pointer;
    letter-spacing: 1px;
    margin-top: 5%; 
    background: linear-gradient(to left, #E51C30, #422A70);
  }

  button[type="submit"]:hover {
    background-color: #0056b3;
  }

/* Estilos para los inputs cuando están enfocados */
  input:focus {
    outline: none; 
    border-bottom-color: #007bff; /* Cambiar el color de la línea inferior */
  }

  .mensaje-enviado-formulario{
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: lightgray;
    padding: 10px 20px;
    font-size: 2.5vh;
    text-align: center;
    width: 96%;
  }
  
  .titulo-formulario-elegir a{
    cursor: pointer;
  }

  }