@import '~typeface-ubuntu/index.css';

/* Mobile styles */
@media(max-width: 768px) {
  .contenedor-titulo-paso-2 {
    display: flex;
    flex-direction: column;
    font-family: 'Ubuntu', sans-serif;
    align-items: center;
    text-align: center; 
  }

  .contenedor-principal-header {
    display: flex;
    align-items: flex-start;
    justify-content: center; 
  }

  .contenedor-titulo-paso-2 {
    display: flex;
    flex-direction: column;
    align-items: center; 
  }

  .titulo-imagen-puntos {
    display: flex;
    align-items: center;
    justify-content: center; 
  }

  .titulo-paso-2 {
    font-size: 4.3vw;
    display: flex;
    flex-wrap: nowrap;
    color: #422A70;
    margin-bottom: 60px;
    justify-content: center; 
  }

  .subtitulo-paso-2 {
    font-size: 3.6vw;
    display: flex;
    flex-wrap: nowrap;
    margin-top: -50px;
    margin-bottom: 0px;
    color: #422A70;
    justify-content: center; 
    text-align: center; 
  }

  .nombre-seguro {
    margin-left: 5px;
  }

  .palabra-cotizador {
    font-weight: bold;
  }
}

/* ---------------------------------------------- */

/* Desktop styles */
@media(min-width: 769px) {

  .contenedor-principal-header{
    display: flex;
    align-items: flex-start;
  }

  .titulo-imagen-puntos{
    display: flex;
    align-items: center;
  }

  .contenedor-titulo-paso-2{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .titulo-paso-2{
    font-size: 3vw;
    display: flex;
    flex-wrap: nowrap;
    color: #422A70;
    margin-bottom: 60px;
  }

  .subtitulo-paso-2{
    font-size: 2vw;
    display: flex;
    justify-content: left;
    flex-wrap: nowrap;
    margin-top: -50px;
    margin-bottom: 0px;
    color: #422A70;
  }

  .nombre-seguro{
    margin-left: 15px;
  }

  .palabra-cotizador{
    font-weight: bold;
  }
}