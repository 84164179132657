/* MiCuenta.css */
.contenedor-principal-mi-cuenta {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  font-family: 'Ubuntu', sans-serif;
}  

.contenedor-acceso-clientes p,
.contenedor-acceso-clientes a{
  font-family: 'Ubuntu', sans-serif;
  font-size: 2.5vh;
  color: white;
}

.texto-acceso-clientes a{
  font-size: 3.5vh;
  font-weight: 500;
  color: white;
  text-align: center;
  text-decoration: underline;
}

/* Desktop styles */
@media(min-width: 769px){
  .contenedor-encabezado-mi-cuenta {
    margin-right: 50px;
    margin-bottom: -5%;
  }

  .contenedor-texto-mi-cuenta {
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 0;
    background-image: url('../media/FotoAccesoClientes.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    flex-direction: column;
    height: 150vh;
    width: 100%;
  }

  .contenedor-acceso-clientes{
    background-color: rgba(1, 53, 69, 0.5);
    background-size: cover;
    border-radius: 20%;
    padding-top: 0%;
    position: absolute;
    top: 10vh;
    right: 100px;
    height: 30vh;
    width: 60vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
  }

  .registrate-texto{
    margin-top: -5%;
  }
} 

/* -------------------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .contenedor-texto-mi-cuenta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end; 
    margin: 0;
    height: 90vh;
    width: 100%;
    position: relative;
    background-color: #f0f0f0; 
  }

  .contenedor-acceso-clientes{
    background-color: #013545;
    background-size: cover;
    border-radius: 10%;
    position: absolute;
    top: 5vh;
    right: 10px;
    height: 200px;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
  }

  .registrate-texto{
    margin-top: -5%;
  }

  .contenedor-imagen-cuenta {
    background-image: url('../media/FotoAccesoClientes.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 50vh;
    margin-bottom: 0; 
  }
}
  
  