.contenedor-principal-negocio{
  display: flex;
  flex-direction: column;
  font-family: 'Ubuntu', 'sans-serif';
}  

 .contenedor-blanco-negocio{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.contenedor-fondo-negocio{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  box-sizing: border-box;
  text-align: center;
  background-image: url('../media/landing2-1 (1).png');
  background-size: cover;
  background-position: bottom;
  height: 80vh;
  width: 100%;
}

.texto-principal-negocio {
  font-size: 2.8vh;
  font-weight: 400;
  margin-top: 35px;
  color: #013545;
}

.subrayado-negocio{
  margin-left: auto;
  margin-right: auto;
  border-top: 0.2vw solid red;
  width: 8vw;
}

.subtitulo-negocio,
.subtitulo-negocio-2 {
  font-size: 2.8vh;
  font-weight: 700;
  margin-top: 35px;
  color: #013545;
}

.texto-negocio {
  font-size: 2.3vh;
  font-weight: 400;
  color: #013545;
}

.requisitos-negocio {
  font-size: 2.4vh;
  font-weight: 300;
  color: #013545;
}

.requisitos-titulo-negocio {
  font-size: 2.3vh;
  font-weight: 300;
  color: #013545;
  text-decoration: underline ;
  cursor: pointer;
}

.imagen-casa-negocio{
  margin-bottom: 0;
  margin-left: 130vh;
  margin-top: -20vh;
}

.img-negocio{
  height: 35vh;
  z-index: -1;
}

.btn-negocio {
  width: fit-content;
  height: 8vh;
  padding: 10px 20px;
  font-size: 2.8vh;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  color: white;
  background: linear-gradient(to right, #E51C30, #422A70);
  cursor: pointer;
  letter-spacing: 1px;
  margin: 0 auto; 
}

/* ---------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .contenedor-fondo-negocio{
    margin-top: 5%;
    height: 100vh;
  }

  .subtitulo-negocio-2 {
    margin-top: 100px;
  }
}



  