/* Desktop styles */
@media(min-width: 768px){ 
 .contenedor-principal-seccion-marcos{
    display: flex;
    flex-direction: row;
    align-content: center;
    width: 100%;
    padding-top: 10px;
  }

  .contenedor-texto-seccion-marcos{
    margin: 10px;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contenedor-imagenes-seccion-marcos{
    margin: 0;
    width: 50%;
    text-align: right;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
  }

  .foto-marcos-inicio{
    position: absolute;
    z-index: 2;
    align-content: baseline;
    bottom: 0;
    width: 23vw;
  }

  .onda-marcos-inicio{
    position: relative;
    z-index: 1;
    margin-top: 5vw;
    left: -30%;
    top: 0%;
  }

  .contenedor-titulo-seccion-marcos{
    font-size: 5vw;
    color: #422A70;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: left;
  }

  .contenedor-subtitulo-seccion-marcos{
    font-size: 2.5vw;
    color: #422A70;
    margin: 0;
    margin-bottom: 10px;
  }

  .contenedor-subtitulo-seccion-marcos span.bold {
    font-weight: bold;
  }

  .subrayado-seccion-marcos{
    border-top: 8px solid red;
    width: 17vw;
    margin-bottom: 10px;
  }

  .boton-wsp {
    display: inline-block;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .wsp-image-seccion-marcos{
    display: flex;
    justify-content: flex-start;
    width: 20vw;
    margin-top: 10px;
  }
}

/* ---------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .contenedor-principal-seccion-marcos{
    display: flex;
    flex-direction: row;
    align-content: center;
    width: 100%;
    left: -1%;
    height: 20vh;
    padding-top: 10px;
    background-color: rgb(223, 223, 223);
  }

  .contenedor-texto-seccion-marcos{
    margin: 10px;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contenedor-imagenes-seccion-marcos{
    margin: 0;
    width: 50%;
    text-align: right;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
  }

  .foto-marcos-inicio{
    position: absolute;
    z-index: 2;
    align-content: baseline;
    bottom: 0;
    width: 35vw;
  }

  .onda-marcos-inicio{
    position: absolute;
    z-index: 1;
    align-content: baseline;
    bottom: 0;
    left: -2%;
    width: 50vw;
  }

  .contenedor-titulo-seccion-marcos{
    font-size: 3.5vh;
    color: #013545;
    font-weight: bold;
    margin-top: -25px;
    margin-bottom: 0px;
    text-align: left;
  }

  .contenedor-subtitulo-seccion-marcos{
    font-size: 2vh;
    color: #013545;
    margin: 0;
    margin-bottom: 5px;
  }

  .contenedor-subtitulo-seccion-marcos span.bold {
    font-weight: bold;
  }

  .subrayado-seccion-marcos{
    border-top: 4px solid red;
    width: 30vw;
    margin-bottom: 5px;
  }

  .boton-wsp {
    display: inline-block;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .wsp-image-seccion-marcos{
    display: flex;
    justify-content: flex-start;
    width: 40vw;
    margin-top: 5px;
  }
}
    