/* Desktop styles */
@media(min-width: 769px){
  .ambito-btn{
      display: flex;
      justify-content: space-between;
      font-family: 'Ubuntu', sans-serif;
      font-weight: 500;
      border-radius: 10px;
      margin-bottom: 20px;
      width: 10vw;
      height: 4vw;
      min-width: 100px;
      min-height: 40px;
      font-size: 1.3vw;
      padding: 0.5vw;
      border: none;
      justify-content: center;
      color: white;
      background: linear-gradient(to right, #323366, #069999); 
      cursor: pointer;
      transition: background 0.3s;
    }
    
  .ambito-btn-clicked{
    display: flex;
    justify-content: space-between;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 10vw;
    height: 4vw;
    min-width: 100px;
    min-height: 40px;
    padding: 0.5vw;
    font-size: 1.3vw;
    border: none;
    justify-content: center;
    color: white;
    background: linear-gradient(to right, #E51C30, #422A70);
  }

}

/* -------------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .ambito-btn,
  .ambito-btn-clicked{
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 5vw;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    min-width: 150px;
    min-height: 40px;
    margin-top: 25px;
    border: none;
    color: white;
    background: linear-gradient(to right, #323366, #069999); 
    transition: background 0.3s;
    letter-spacing: 1px;
  }
  
  .ambito-btn-clicked{
    background: linear-gradient(to right, #E51C30, #422A70);
  }
}
