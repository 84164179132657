.contenedor-principal-asociaciones{
    display: flex;
    flex-direction: column;
    font-family: 'Ubuntu', 'sans-serif';
  }  

 .contenedor-blanco-asociaciones{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
 }

  .contenedor-fondo-asociaciones{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 0;
    box-sizing: border-box;
    text-align: center;
    background:  #e8f8f8;
    background-size: cover;
    background-position: bottom;
    height: 100vh;
    width: 100%;
  }

 .texto-principal-asociaciones {
    font-size: 2.8vh;
    font-weight: 400;
    margin-top: 35px;
    color: #013545;
  }

  .subrayado-asociaciones{
    margin-left: auto;
    margin-right: auto;
    border-top: 0.2vw solid red;
    width: 8vw;
  }

  .subtitulo-asociaciones {
    font-size: 2.8vh;
    font-weight: 700;
    color: #013545;
    margin-bottom: 15px;
  }

  .texto-asociaciones {
    font-size: 2.5vh;
    font-weight: 400;
    color: #013545;
  }

  .check-mark {
    color: red; 
}

  .btn-asociaciones {
    width: fit-content;
    height: 7vh;
    /* min-width: 45vh; */
    padding: 10px 20px;
    font-size: 2.8vh;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    border: none;
    color: white;
    background: linear-gradient(to right, #E51C30, #422A70);
    cursor: pointer;
    letter-spacing: 1px;
    margin: 0 auto; 
    margin-top: 35px;
  }

