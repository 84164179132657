/* Desktop styles */
@media(min-width: 769px){
  .lista-tipo-seguro{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 70px;
    }
}

/* -------------------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .lista-tipo-seguro{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top:25px;
    }
}
