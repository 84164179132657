@import '~typeface-ubuntu/index.css';

/* Desktop styles  */
@media(min-width: 769px) {
  .Inicio{
    background-size: 18%;
    background-color:white;
    background-position: top left ;
    background-repeat: no-repeat;
    font-family: 'Ubuntu', sans-serif;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    height: auto;
    margin-left: 0;
  }

  .main-container{
    height: 50vh;
    width: 100%;
    margin-left: 15%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contenedor-img-puntos-texto{
    display: flex;
    height: 28vw;
    min-height: 350px;
    margin-bottom: 2px;
  }

  .contenedor-imagen-puntos-inicio{
    display: flex;
    width: 10vw;
  }

  .imagen-puntos{
    height: 100%;
  }

  .cotizador-container{
    display: flex;
    flex-direction: row;
    align-content: center;
    /*width: 100%;*/
    margin-left: -0.5vw;
  }

  .titulo-inicio{
    font-size: 2.5vw;
    margin-top: 20px;
    color: #422A70;
    text-align: center;
    width: 100%;
  }
  
  .titulo2-inicio{
    font-size: 3.5vw;
    color: #422A70;
    text-align: center;
    width: 100%;
    margin-top: 5px;
    }

  .btn-asesoria {
    margin-top: 20px;
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
  }

  .btn-asesoria img {
    width: 250px;  /* Ajusta el tamaño de la imagen según tus necesidades */
    height: auto; /* Ajusta el tamaño de la imagen según tus necesidades */
    transition: transform 0.3s ;  /* Agrega una transición suave al cambio de imagen */
    }

  .btn-asesoria:hover img {
    transform: scale(1);  /* Escala de la imagen al hacer hover para un efecto visual */
  }

  .pieDePagina-container{
    margin-left: -0.5vw;
    /*width: 100%;*/
  }
  
  .marcos-seccion-container{
    width: 100%;
    background-color: white;
  }

  .gif-container{
    position: relative;
    z-index: 3;
    object-fit: cover;
    bottom: -28vh;
    right: 2%;
  }
}

/* ------------------------------------------------ */

/* Mobile styles */
@media(max-width: 768px){
  .Inicio{
    background-size: 14%;
    background-color:white;
    background-position: top left ;
    background-repeat: no-repeat;
    font-family: 'Ubuntu', sans-serif;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    height: auto;
    margin-left: 0;
  }

  .contenedor-img-puntos-texto{
    display: flex;
    min-height: 140px;
    align-items: center;
    height: 140px;
    width: fit-content;
  }

  .contenedor-imagen-puntos-inicio{
    display: flex;
    align-content: flex-end;
    align-items: flex-end;
    height: 100%;
    width: 50px;
  }

  .imagen-puntos{
    height: 78%;
  }

  .main-container{
    height: 100%;
    margin-bottom: 10px;
    width:70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 2%;
  }

  .contenedor-mobile {
    display: flex;
    flex-direction: column; /* Apila los elementos verticalmente */
    align-items: center; /* Alinea los elementos al final del contenedor */
    position: relative;
    margin-right: 15px;
    margin-top: 5%;;
  }
  
    .titulo-inicio {
    font-size: 3.8vw;
    text-align: center;
    color: #422A70;
    width: 100%;
    margin-bottom: 0px;
    }
  
   .titulo2-inicio{
    font-size: 4.8vw;
    text-align: center;
    color: #422A70;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 5px;
    }

  .btn-asesoria {
    margin-top: 2px;
    margin-left: -6%;
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
  }

  .btn-asesoria img {
    width: 120px;  /* Ajusta el tamaño de la imagen según tus necesidades */
    height: auto; /* Ajusta el tamaño de la imagen según tus necesidades */
    transition: transform 0.3s ;  /* Agrega una transición suave al cambio de imagen */
  }

  .btn-asesoria:hover img {
    transform: scale(1);  /* Escala de la imagen al hacer hover para un efecto visual */
  }
  
  .cotizador-container{
    display: flex;
    flex-direction: row;
    align-content: center;
    width: 100%;
    margin-left: -0.5vw;
  }
  
  .pieDePagina-container{
    margin-left: -0.5vw;
    width: 100%;
  }
  
  .marcos-seccion-container{
    width: 100%;
    background-color: white;
  }

  .gif-container{
    position: relative;
    z-index: 3;
    object-fit: cover;
    bottom: -7vh;
    right: 10%;
  }
}





