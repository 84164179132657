/* Desktop styles */
@media(min-width: 769px) {
.estilo-boton, 
.estilo-boton-volver {
    margin-top: 20px;
    width: 4vw; 
    height: 4vw;
    min-height: 50px;
    min-width: 50px;   
    border-radius: 15px;
    position: end;
    border: none; 
    cursor: pointer; 
    overflow: hidden;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    background-color: #ac2c54;
  }
  
  .boton-image {
    width: 115%; 
    height: 115%; 
    object-fit: cover;
    transform: scale(1.8); /* Hace zoom en la imagen */
    transition: transform 0.3s ease; 
  }

  .boton-image-volver {
    width: 115%; 
    height: 115%;
    object-fit: cover;
    transform: scale(1.8) rotate(180deg);
    transform-origin: center;
    position: relative;
    top: -10%;
    left: -10%;
    transition: transform 0.3s ease;
  }
}

/* --------------------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px) {
  .estilo-boton,
  .estilo-boton-volver  {
    margin-top: 25px;
    width: 4vw; 
    height: 4vw;
    min-height: 50px;
    min-width: 50px;   
    border-radius: 15px;
    position: end;
    border: none; 
    cursor: pointer; 
    overflow: hidden;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    background-color: #ac2c54;
  }

  .boton-image {
    width: 120%; 
    height: 120%; 
    object-fit: cover;
    transform: scale(1.8); /* Hace zoom en la imagen */
    transition: transform 0.3s ease; 
  }

  .boton-image-volver {
    width: 125%; /* Ajusta el ancho para pantallas más pequeñas */
    height: 125%;
    transform: scale(2) rotate(180deg); /* Ajusta la escala para pantallas móviles */
    transform-origin: center;
    position: relative;
    top: -10%;
    left: -10%;
    transition: transform 0.3s ease;
  }
}



  
  