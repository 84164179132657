.btn-red-social {
  text-decoration: none; /* Elimina el subrayado del enlace */
  display: inline-block; /* Alinea los botones en línea */
}
  
.btn-red-social:hover img {
  filter: brightness(80%); /* Reduce el brillo al 80% */
}

/* Desktop styles */
@media(min-width: 769px){
  .btn-red-social img {
    width: 3vw; 
    height: auto;
    border-radius: 30; 
  }

/* Espacio entre los botones */
  .btn-red-social {
    margin-right: 20px; 
  }
}

/* -------------------------------------------------------- */

 /* Mobile styles */
@media(max-width: 768px){
  .btn-red-social img {
    width: 25px; 
    height: auto; 
    border-radius: 30; 
  }

  .btn-red-social {
    margin-right: 10px; 
  }
}


  