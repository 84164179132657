@import '~typeface-ubuntu/index.css';

/* Desktop styles */
@media(min-width: 769px){
  .contenedor-AP {
    display: flex;
    flex-direction: row;
    min-height: 150vh;
    height: fit-content;
    align-items: center ;
    width: 98vw;
    margin: 0;
    background-color: white;
    position: relative;
    left: 0;
  }

  .contenedor-video-AP {
    width: 40%; 
    height: 130vh;
    justify-content: center;
    padding-left: 40px;
  }

  .contenedor-texto-AP {
    border-radius: 60px; /* Borde redondeado */
    width: 60%; 
    height: fit-content;
    min-height: 130vh;
    padding: 50px;
    width: 50vw;
    min-width: 500px;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombreado */;
    margin: 0;
    background-color: white;
  }

  .contenedor-vigencia,
  .contenedor-poliza{
    flex-direction: row;
    display: flex;
  }

  .contenedor-titulo-y-texto{
    margin-top: 40px;
  }

  .titulo-AP {
    font-size: 38px;
    font-weight: 800;
    font-family: 'Ubuntu', 'sans-serif';
    color: #013545;
    text-align: left;
  }

  .subtitulo-AP, 
  .titulo-observaciones-AP,
  .vigencia-titulo {
    font-family: 'Ubuntu', sans-serif;
    color: #013545;
  }

  .subtitulo-AP {
    font-size: 18px;
    font-weight: 700;
    margin-top: 15px;
  }

  .texto-AP {
    font-size: 14px;
    font-weight: 500;
  }

  .texto-AP p,
  .observaciones-AP p,
  .vigencia-texto{
    margin: 0.5vw 0;
  }

  .texto-AP,
  .observaciones-AP,
  .poliza-titulo,
  .poliza-texto,
  .poliza-texto a,
  .observaciones-AP a{
    font-family: 'Ubuntu', sans-serif;
    color: black;
  }
  
  .texto-AP a{
    font-family: 'Ubuntu', sans-serif;
    color: #013545;
  }


  .titulo-observaciones-AP {
    font-size: 15px;
    font-weight: 700;
    margin-top: 2vw;
  }

  .observaciones-AP {
    font-size: 13px;
    font-weight: 500;
  }

  .vigencia-titulo{
    font-size: 15px;
    font-weight: 700;
    margin-right: 3vw;
    margin-top: 2vw;
  }

  .vigencia-texto{
    font-size: 13px;
    font-weight: 500;
  }

  .poliza-titulo{
    font-size: 13px;
    font-weight: 600;
    margin-right: 3vw;
    margin-top: 1vw;
  }

  .poliza-texto{
    font-size: 12px;
    font-weight: 400;
  }
}

/* -------------------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
.contenedor-AP {
  display: flex;
  flex-direction: column;
  height: 200vh;
  align-items: center ;
  width: 98vw;
  background-color: white;
}

.contenedor-video-AP {
  width: 100%; 
  height: 400vh;
  justify-content: center;
  padding-left: 10%;
  margin-top: 2%;
}

.contenedor-texto-AP {
  border-radius: 30px; /* Borde redondeado */
  height: fit-content;
  height: 300vh;
  padding: 20px;
  width: 100%; 
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombreado */;
  margin-top: 2%;
  background-color: white;
}

.titulo-AP {
  font-size: 20px;
  font-weight: 800;
}

.subtitulo-AP {
  font-size: 15px;
  font-weight: 750;
  margin-top: 15px;
}

.texto-AP {
  font-size: 10px;
}

.texto-AP p,
.observaciones-AP p,
.vigencia-texto{
  margin: 2vw 0;
}

.titulo-observaciones-AP {
  font-size: 10px;
  font-weight: 700;
  margin-top: 2vw;
}

.observaciones-AP {
  font-size: 10px;
}

.vigencia-titulo{
  font-size: 9px;
  font-weight: 700;
  margin-right: 3vw;
  margin-top: 2vw;
}

.vigencia-texto{
  font-size: 11px;
  font-weight: 400;
}

.poliza-titulo{
  font-size: 9px;
  font-weight: 600;
  margin-right: 3vw;
  margin-top: 1vw;
}

.poliza-texto{
  font-size: 8px;
  font-weight: 400;
}

}

