@import '~typeface-ubuntu/index.css';

/* Desktop styles */
@media(min-width: 769px){
  .estilo-input,
  .estilo-input-error {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.2vw;
    font-weight: 400;
    width: 40vw;
    padding: 12px 20px;
    margin-top: 25px;
    box-sizing: border-box;
    border: 1px solid #72336a;
    border-radius: 10px;
    outline: none;
    display: flex;
    caret-color: black;
  }

  .estilo-input-error{
    color: red;
  }
  
  .estilo-input:focus {
    border: 2px solid #72336a;
  }
}

/* -------------------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .estilo-input,
  .estilo-input-error {
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    font-weight: 200;
    width: 300px;
    height: 50px;
    padding: 12px 20px;
    margin-top: 25px;
    box-sizing: border-box;
    border: 1px solid #72336a;
    border-radius: 10px;
    outline: none;
    display: flex;
  }

  .estilo-input-error{
    color: red;
  }

  .estilo-input:focus {
    border: 2px solid #72336a;
  }  
}